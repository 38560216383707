import React, { useEffect, useState } from "react";
import {
  Button,
} from "@material-ui/core";
import './App.css'
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import * as tf from "@tensorflow/tfjs";
import Webcam from "react-webcam";
import ReactPlayer from 'react-player';
import testImage from './images/D.jpg';
//import testVideo from './images/galleria.mp4';

function App() {
  const webcamRef = React.useRef(null);
  const imageRef = React.useRef(null);
  const videoRef = React.useRef(null);
//  const [ videoWidth ] = useState(960);
//  const [ videoHeight ] = useState(640);
  const [ videoWidth ] = useState(1553);
  const [ videoHeight ] = useState(993);
  const [ refreshID, setRefresh ] = useState(0);
  const [ inputType, setInputType ] = useState('webcam');
  const [ tensor, setTensor ] = useState(null);
  const [ preds, setPreds ] = useState(null);
  const [ img, setImg ] = useState(null);
  const [ playing, setPlaying ] = useState(null);
  const [ facingMode, setFacingMode ] = useState('user');

  const [model, setModel] = useState();

  async function loadModel() {
    try {
      const model = await cocoSsd.load();
      setModel(model);
      console.log("setloadedModel");
    } catch (err) {
      console.log(err);
      console.log("failed load model");
    }
  }

  const videoConstraints = {
    height: videoHeight,
    width: videoWidth,
    maxWidth: "100vw",
    facingMode: facingMode,
  };

  let timeoutID;

  useEffect(() => {
    tf.ready().then(() => {
      console.log('START loadModel');
      loadModel();
    });
  }, []);

  function toggleRefresh() {
    if (refreshID === 1) {
      setRefresh(0);
      clearTimeout(timeoutID);
    } else {
      setRefresh(1);
    }
  }

  function toggleSource() {
    if (inputType === 'webcam') {
      setInputType('image');
      clearTimeout(timeoutID);
    } else if (inputType === 'image') {
      setInputType('webcam');
      clearTimeout(timeoutID);
    } else {
      setInputType('webcam');
    }
  }

  function toggleCamera() {
    if (facingMode === 'user') {
      setFacingMode('environment');
    } else {
      setFacingMode('user');
    }
  }

  async function predictionFunction() {
/*
console.log('a');
    const img = document.createElement('img2');
console.log('b');
    const canvas = document.createElement('canvas');
console.log('c');    
    const ctx2 = canvas.getContext('2d');
console.log('d');
    ctx2.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
console.log('e');
    img.src = canvas.toDataURL();
console.log('img', img.width, img.height);
console.log('f');
    img.onload = () => {
      console.log('image loaded with video');
      const tensor = tf.browser.fromPixels(img);
      // Use the tensor in your TensorFlow.js model
      console.log('tensor created');
    };
console.log('past img load');
*/

    var cnvs = document.getElementById("myCanvas");

    console.log('START PREDICTION', refreshID);

    let predictions;
    if (inputType === 'webcam') {
      predictions = await model.detect(document.getElementById("img"), 100, 0.10);
      cnvs.width =  webcamRef.current.video.videoWidth;
      cnvs.height = webcamRef.current.video.videoHeight;
    } else if (inputType === 'image') {
      predictions = await model.detect(document.getElementById("img2"), 100, 0.10);
      cnvs.width =  imageRef.current.clientWidth;
      cnvs.height = imageRef.current.clientHeight;
    } else if (inputType === 'video') {
      predictions = await model.detect(document.getElementById("vid"), 100, 0.10);
      cnvs.width =  videoWidth;
      cnvs.height = videoHeight
    }

    var ctx = cnvs.getContext("2d");

    if (inputType === 'webcam') {
      ctx.clearRect(
        0,
        0,
        webcamRef.current.video.videoWidth,
        webcamRef.current.video.videoHeight
      );
    } else if (inputType === 'image') {
      ctx.clearRect(
        0,
        0,
        imageRef.current.clientWidth,
        imageRef.current.clientHeight
      )
    } else if (inputType === 'video') {
      ctx.clearRect(
        0,
        0,
        videoWidth,
        videoHeight
      )
    }
    if (predictions.length > 0) {
      // setPredictionData(predictions);
      console.log(predictions);
      console.log("number of matches", predictions.length);
      for (let n = 0; n < predictions.length; n++) {
        // Check scores
        //console.log(n);
        if (predictions[n].score > 0.1 && predictions[n].class === 'person') {
          let bboxLeft = predictions[n].bbox[0];
          let bboxTop = predictions[n].bbox[1];
          let bboxWidth = predictions[n].bbox[2];
          let bboxHeight = predictions[n].bbox[3]; // - bboxTop;

          //console.log("box:", bboxLeft, bboxTop, bboxWidth, bboxHeight);

          ctx.beginPath();
          ctx.font = "12px Arial";
          ctx.fillStyle = "white";

          ctx.fillText(
            predictions[n].class +
              ": " +
              Math.round(parseFloat(predictions[n].score) * 100) +
              "%",
            bboxLeft,
            bboxTop - 5
          );

          ctx.rect(bboxLeft, bboxTop, bboxWidth, bboxHeight);
          if (predictions[n].score < 0.2) {
            ctx.strokeStyle = "red";
          } else if (predictions[n].score < 0.4) {
            ctx.strokeStyle = "orange";
          } else if (predictions[n].score < 0.6) {
            ctx.strokeStyle = "yellow";
          } else if (predictions[n].score < 0.8) {
            ctx.strokeStyle = "blue";
          } else {
            ctx.strokeStyle = "green";
          }

          ctx.lineWidth = 4;
          ctx.stroke();

          //console.log("detected");
        }
      }
    }

    if (refreshID === 1) {
      timeoutID = setTimeout(() => predictionFunction(), 100);
    } else {
      clearTimeout(timeoutID);
    }
  }

  return (
    <div className="App">
      <div style={{ position: "absolute", top: "800px", zIndex: "10000" }}>
        <Button variant="contained" onClick={() => {predictionFunction();}} mr={2}>PREDICT</Button>
        <Button variant="contained" onClick={() => {toggleRefresh();}} mr={2}>TOGGLE {refreshID}</Button>
        <Button variant="contained" onClick={() => {toggleSource();}} mr={2}>TOGGLE {inputType}</Button>
        <Button variant="contained" onClick={() => {videoRef.current.play()}} mr={2}>PLAY {inputType}</Button>
        <Button variant="contained" onClick={() => {videoRef.current.pause()}} mr={2}>PAUSE {inputType}</Button>
        <Button variant="contained" onClick={() => {toggleCamera()}} mr={2}>CAMERA {facingMode}</Button>

      </div>
      <div style={{ position: "absolute", top: "50px", left: "50px", zIndex: "9999" }}>
        <canvas
          id="myCanvas"
          width={videoWidth}
          height={videoHeight}
          style={{ backgroundColor: "transparent" }}
        />
      </div>
      <div style={{ position: "absolute", top: "50px", left: "50px", zIndex: "1" }}>
        <div>
        { inputType === 'webcam' &&
          <Webcam
            audio={false}
            id="img"
            ref={webcamRef}
            width={videoWidth}
            height={videoHeight}
            screenshotQuality={1}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        }
        { inputType === 'image' &&
          <img id="img2"
            style={{ width: videoWidth, objectFit: "fill" }}
            src={testImage}
            alt="testing"
            ref={imageRef}
          ></img>
        }
        {/*
          inputType === 'video' && 
          <video id="vid" src={testVideo} ref={videoRef} playing="playing" muted="muted" autoPlay="autoPlay"/>
        */}
        </div>
      </div>
      
    </div>
  );
}

export default App;
